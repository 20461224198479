import React, { useEffect, useState } from 'react'
import ArticleComponent from './ArticleComponent'
import useBreakpoint from '../../customHooks/useBreakpoint'
import { LoadableQuintNavHeader } from '../LoadableComponents'
import { navigate } from 'gatsby-link'
import Seo from '../SEO'

const tabs = [
  'All',
  'Design',
  // 'Figma',
  'Development',
  'Business'
]

const tabsUri = [
  '/',
  '/design',
  // '/figma',
  '/development',
  '/business'
]

const BlogPostTemplate = ({ pageContext }) => {
  if (!pageContext) return
  const isMobile = useBreakpoint('sm', false)

  const pageData = pageContext.blog.node
  const data = pageContext.blog.node.data

  const url = typeof window !== 'undefined' ? window.location.href : ''
  const splitUri = url.split('/')
  const uri = splitUri[splitUri.length - 1]

  const pageTab = () => {
    if (uri) {
      const splitUri = uri.split('/')
      const page = splitUri[splitUri.length - 1]
      switch (page) {
        case '':
          return 0
        case 'design':
          return 1
        // case 'figma':
        //   return 2
        case 'development':
          return 2
        case 'business':
          return 3
        default:
          return 0
      }
    }
    return 0
  }

  const handleTabSelect = (index) => {
    navigate(`/blog${tabsUri[index]}`)
  }

  const [selectedTab, setSelectedTab] = useState(pageTab)

  useEffect(() => {
    setSelectedTab(pageTab)
  }, [uri])

  return (
    <>
      <Seo
        title={data.meta_title}
        description={data.meta_description}
        image={data.background && data.background.url}
        type='article' />
      <div
        className='content-container' style={{
          marginTop: 104,
          justifyContent: 'flex-start',
          padding: 0
        }}>
        <LoadableQuintNavHeader
          blog tabs={tabs}
          color='#25317B' setSelectedTab={handleTabSelect}
          selectedTab={selectedTab} backgroundColor='#EFF8FF'
          borderColor='#EFF8FF'
          style={{ marginTop: 85 }} key='quint-nav-header-blog-section' />
        <div className='content-container padding-0' style={{ marginTop: 72 }}>
          <div
            className='quint-container-lg'
            style={{ overflowY: 'hidden', padding: '72px 0', width: '100%' }}>
            <ArticleComponent
              size='xl'
              background={data.background}
              icon={data.icon}
              date={data.date}
              name={data.name}
              tags={pageData.tags}
              content={data.content}
              subtitle={data.title}
              style={isMobile ? { maxWidth: '100%' } : {}}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default BlogPostTemplate
